import { AfterContentInit, Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { clampDay } from '@app/_helpers/date-fns';
import {
  DEFAULT_PERMISSION_GROUPS,
  distinctUntilChangedJson,
  fromRxValue,
  hasPermission,
  isNullOrUndefined,
  nextTickAsync,
} from '@app/_helpers/utils';
import { WorkspaceUser } from '@app/_classes/timeghost';
import { CustomValidators } from '@app/_validators/custom-validators';
import { AppService } from '@app/app.service';
import { TaskPickerDialogComponent } from '@app/components/task-picker-dialog/task-picker-dialog.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEventTimesChangedEvent } from 'angular-calendar';
import { CalendarEvent } from 'calendar-utils';
import {
  addMinutes,
  addSeconds,
  differenceInSeconds,
  endOfDay,
  format as formatString,
  intervalToDuration,
  isValid,
  parse as parseFromString,
  startOfDay,
} from 'date-fns/esm';
import { addHours, isAfter, isSameDay, parseISO, parse as parsefp, subHours, subMinutes } from 'date-fns/esm/fp';
import { flow } from 'lodash';
import { MediaObserver } from 'ngx-flexible-layout';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { delay, distinctUntilChanged, filter, finalize, map, shareReplay, startWith, tap } from 'rxjs/operators';
import {
  FeedEntry,
  FeedQuery,
  Logger,
  MyTimesQuery,
  MyTimesService,
  Project,
  ProjectsQuery,
  Tag,
  TagType,
  Task,
  Time,
  UserSettings,
  UserSettingsQuery,
  Workspace,
} from 'timeghost-api';

import { CosmosEntity } from '@app/_classes/cosmos-entity';
import { ObserveFormGroupErrors, useFormErrorObservable } from '@app/_helpers/get-error-observable';
import parseSubscriptionAsStatus from '@app/_helpers/parseSubscriptionAsStatus';
import { parseDurationSeconds } from '@app/components/duration-input-control/duration-input-utils';
import {
  UserSinglePickerDialogComponent,
  UserSinglePickerDialogData,
} from '@app/components/user-single-picker-dialog/user-single-picker-dialog.component';
import defaults from '@env/defaults';
import {
  ClientProjectDialogData,
  ClientProjectPickerDialogComponent,
} from '../dialogs/client-project-picker-dialog/client-project-picker-dialog.component';
import { TagDialogData, TagPickerDialogComponent } from '../dialogs/tag-picker-dialog/tag-picker-dialog.component';
import { RecordToolbarService } from '../record-toolbar/record-toolbar.service';
import { TimeDatePickerConfig } from '../time-date-picker/time-date-picker-config';
import { TimeDatePickerComponent } from '../time-date-picker/time-date-picker.component';
import { TimeTrackerCalendarStepperCreateDialogComponent } from '../time-tracker-calendar-stepper-create-dialog/time-tracker-calendar-stepper-create-dialog.component';
export const VerifyBlockTimePast: (currentuser: () => UserSettings, choosenUser: () => string) => ValidatorFn = (
  _currentuser,
  _choosenUser,
) => {
  return (form: FormControl<Date>) => {
    const blockTimes = _currentuser().workspace.settings.blockTimes;
    const mode: string = blockTimes.pastMode;
    const date = form.getRawValue();
    const currentuser = _currentuser();
    const choosenUser = _choosenUser();
    const isAdmin = !!currentuser.workspace.users.find((x) => x.admin && x.id === currentuser.id);
    let allowedDate;
    if (mode === 'none') {
      return null;
    } else if (mode == 'date') {
      allowedDate = new Date(blockTimes.pastDate);
    } else {
      const period = blockTimes.period;
      const range: string = blockTimes.range;
      allowedDate = startOfDay(new Date());
      if (range === 'day') {
        allowedDate.setDate(allowedDate.getDate() - period);
      } else if (range === 'week') {
        allowedDate.setDate(allowedDate.getDate() - period * 7);
      } else if (range === 'month') {
        allowedDate.setMonth(allowedDate.getMonth() - period);
      }
    }
    if (isAdmin) {
      return null;
    }
    if (currentuser.id != choosenUser) {
      return;
    }
    if (date >= allowedDate) {
      return null;
    } else {
      return {
        blockEdit: true,
      };
    }
  };
};

const log = new Logger('TimeTrackerCalendarCreateDialogComponent');
@ObserveFormGroupErrors()
@UntilDestroy()
@Component({
  selector: 'app-time-tracker-calendar-create-dialog',
  templateUrl: './time-tracker-calendar-create-dialog.component.html',
  styleUrls: ['./time-tracker-calendar-create-dialog.component.scss'],
})
export class TimeTrackerCalendarCreateDialogComponent implements OnInit, AfterContentInit {
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }
  get mode() {
    return this.userSettingsQuery.getValue()?.workspace.settings?.timesMode;
  }
  group = new FormGroup({
    name: new FormControl('', [
      Validators.minLength(3),
      (ctrl) => {
        const user = this.userSettingsQuery.getValue();
        if (user.workspace?.settings?.requireName && !ctrl.value) {
          return {
            required: true,
          };
        }
        return null;
      },
    ]),
    project: new FormControl<Project>(null, [
      (ctrl) => {
        const user = this.userSettingsQuery.getValue();
        if (user.workspace?.settings?.requireProject) {
          const projectValue = ctrl.value as Project;
          if (!projectValue || projectValue.useAsDefault)
            return {
              required: true,
            };
        }
        return null;
      },
    ]),
    task: new FormControl<Task>(null, [
      (ctrl) => {
        const user = this.userSettingsQuery.getValue();
        if (user.workspace?.settings?.requireTask) {
          if (!ctrl.value?.id)
            return {
              required: true,
            };
        }
        return null;
      },
    ]),
    billable: new FormControl(false),
    date: new FormControl(null, {
      validators: [
        VerifyBlockTimePast(
          () => this.userSettingsQuery.getValue(),
          () => {
            const user = this.group?.getRawValue()?.user as WorkspaceUser;
            if (!user) return null;
            const id = user.id;
            return id;
          },
        ),
        Validators.required,
      ],
    }),
    start: new FormControl(null),
    end: new FormControl(null),
    duration: new FormControl(null),
    tags: new FormControl<Tag[]>([]),
    user: new FormControl<Workspace['users'][0]>(null),
  });
  defaultProject: Project;
  events$ = combineLatest([
    this.group.valueChanges.pipe(distinctUntilChangedJson()),
    this.myTimesQuery.selectAll().pipe(startWith(this.myTimesQuery.getAll())),
    this.feedQuery.selectAll(),
  ]).pipe(
    map(
      ([groupValue, times, feed]: [
        { date: Date; name: string; start: string; end: string; duration: number },
        Time[],
        FeedEntry[],
      ]) => {
        const date: Date = groupValue?.date || new Date();
        const duration = !this.stateVisibleMode && parseDurationSeconds(groupValue.duration);
        const activeTime: CalendarEvent = {
          id: this.data.outlookRefId || 'custom',
          title: groupValue.name,
          ...(this.stateVisibleMode
            ? {
                start: flow((val) => parseFromString(val, 'HH:mm', date))(groupValue.start),
                end: flow((val) => parseFromString(val, 'HH:mm', date))(groupValue.end),
              }
            : {
                // duration
                start: flow(startOfDay)(date),
                end: flow(startOfDay, (val) => addSeconds(val, duration))(date),
              }),
          draggable: true,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          cssClass: 'event-item event-item-active',
          color: {
            primary: '#51BF43',
            secondary: '#51BF43',
          },
        };
        log.debug('current', activeTime);
        return times
          .filter((x) => x.start && x.end && (isSameDay(parseISO(x.start))(date) || isSameDay(parseISO(x.end))(date)))
          .map(
            (t) =>
              <CalendarEvent>{
                id: t.outlookCalenderReference || t.id,
                title: t.name,
                start: new Date(t.start),
                end: new Date(t.end),
                allDay: false,
                draggable: false,
                resizable: {
                  beforeStart: false, // this allows you to configure the sides the event is resizable from
                  afterEnd: false,
                },
                cssClass: 'event-item',
              },
          )
          .concat(
            feed
              .filter((t) => t.calendar)
              .map(
                (t) =>
                  <CalendarEvent>{
                    id: 'feed_' + t.id,
                    title: t.name,
                    start: new Date(t.start),
                    end: new Date(t.end),
                    allDay: false,
                    draggable: false,
                    resizable: {
                      beforeStart: false, // this allows you to configure the sides the event is resizable from
                      afterEnd: false,
                    },
                    cssClass: 'event-item',
                  },
              ),
          )
          .concat(activeTime);
      },
    ),
    shareReplay(),
    tap((x) => log.debug(x)),
  );

  private _data: TimeTrackCreateData;
  public get data(): TimeTrackCreateData {
    return this._data;
  }
  @Input()
  public set data(v: TimeTrackCreateData) {
    this._data = v;
    this.initializeGroup();
  }
  settings$allowFutureTimeTracking = this.userSettingsQuery
    .select((x) => x.workspace.settings)
    .pipe(map((x) => (x.allowFutureTimeTracking ? null : new Date())));

  constructor(
    @Optional()
    private ref: MatDialogRef<TimeTrackerCalendarCreateDialogComponent>,
    @Optional()
    private stepperCreate: TimeTrackerCalendarStepperCreateDialogComponent,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private dialogData: TimeTrackCreateData,
    private myTimes: MyTimesService,
    private myTimesQuery: MyTimesQuery,
    private feedQuery: FeedQuery,
    private projectsQuery: ProjectsQuery,
    private media: MediaObserver,
    private translate: TranslateService,
    private userSettingsQuery: UserSettingsQuery,
    private dialog: MatDialog,
    private appService: AppService,
    private recordService: RecordToolbarService,
    private el: ElementRef<HTMLElement>,
  ) {
    if (this.dialogData) this.prepareData(this.dialogData);
  }
  currentFormat$ = this.appService.timeFormat$;

  readonly workspace$isAdmin = fromRxValue(
    this.userSettingsQuery.select((x) => hasPermission(DEFAULT_PERMISSION_GROUPS.Admin, x)),
  );

  readonly postDiffUser$ = combineLatest([
    this.group.valueChanges.pipe(
      map((x) => x?.user?.id),
      distinctUntilChanged(),
    ),
    this.userSettingsQuery.select(),
  ]).pipe(map(([uid, user]) => uid && uid !== user.id));
  get isMobile() {
    return this.media.isActive(['xs', 'sm']);
  }
  get i18n() {
    return this.data.lang;
  }
  resetProject() {
    this.patchValue({
      project: this.defaultProject,
      task: null,
    });
  }
  private prepareData(data: TimeTrackCreateData) {
    const now = new Date();
    this.defaultProject = this.projectsQuery
      .getAll({ filterBy: (x) => x.useAsDefault })
      .find((x) => x.useAsDefault === true);
    data.start = data.start || new Date(now.getTime());
    if (data.end) {
      data.end = ((_end) => (differenceInSeconds(_end, data.start) <= 0 ? addHours(data.start, 1) : _end))(
        isAfter(endOfDay(new Date(data.start.getTime())), data.end)
          ? endOfDay(new Date(data.start.getTime()))
          : data.end,
      );
    } else {
      data.end = addHours(1)(new Date(data.start.getTime()));
    }
    const settings = this.userSettingsQuery.getValue().workspace.settings;
    data.timeDiff =
      isNullOrUndefined(data.timeDiff) && !data.end
        ? defaults.MIN_DURATION_MINUTES * 60
        : (data.timeDiff ?? differenceInSeconds(data.start, data.end));
    this.data = data;
  }
  private initializeGroup() {
    const mode = this.mode;
    const project = this.data.project || this.defaultProject,
      task = this.data.task;
    let start = this.data.start,
      end = ((date) => (isSameDay(start, date) ? date : endOfDay(start.getTime())))(
        isValid(this.data.end) ? this.data.end : addMinutes(start.getTime(), defaults.MIN_DURATION_MINUTES),
      );
    if (start > end) start = end; // revert to end if start is higher than end
    const date = this.data.date || new Date(start.getTime());
    const currentUser = this.userSettingsQuery.getValue();
    const tuser = this.data.user || currentUser.workspace.users.find((x) => x.id === currentUser.id);
    const formatDur = flow(
      (d) => addSeconds(d, (end.getTime() - start.getTime()) / 1000),
      (d) => formatString(d, 'HH:mm'),
    );
    this.patchValue({
      name: this.data.title || '',
      start: formatString(start, 'HH:mm'),
      date,
      end: formatString(end, 'HH:mm'),
      duration: formatDur(startOfDay(new Date())),
      tags: this.data.tags || [],
      billable: this.data.billable ?? !!project?.billable,
      project: project || null,
      task: task || null,
      user: tuser as any,
    });
    if (mode === 'duration') {
      this.group.controls.duration.setValidators([
        Validators.required,
        CustomValidators.validDateFormat('HH:mm'),
        CustomValidators.minDuration(60e3),
      ]);
    } else if (mode !== 'range') {
      this.group.controls.start.clearValidators(),
        this.group.controls.end.clearValidators(),
        this.group.controls.duration.clearValidators();
    } else {
      this.group.controls.start.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]),
        this.group.controls.end.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]),
        this.group.controls.duration.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]);
      this.group.setValidators([CustomValidators.timeDiffCheck('start', 'end', false)]);
    }

    const markControl = (name: string) => {
      const ctrl = this.group.controls[name];
      ctrl.markAsTouched(), ctrl.updateValueAndValidity();
    };
    this.group.updateValueAndValidity();
    if (this.group.value.name?.length) markControl('name');
    if (currentUser.workspace.settings?.requireProject || currentUser.workspace.settings?.requireTask) {
      markControl('project');
      markControl('task');
    }
    log.debug('user', currentUser);
  }
  trackId(_: number, { id }: { id: string }) {
    return id;
  }
  get platform() {
    return this.appService.platform;
  }
  focusCalendarNow() {
    const calContainer = this.el.nativeElement.querySelector('#cal-day-view');
    if (calContainer?.scrollTop !== undefined) {
      calContainer.scrollTop = this.data.start.getHours() * 30 * 4;
    }
  }
  get stateVisibleMode() {
    return this.recordService.group.value.inputMode === 'range';
  }
  set stateVisibleMode(v: boolean) {
    if (this.userSettingsQuery.getValue().workspace?.settings?.timesMode === 'range_optional') {
      const inputMode = v ? 'range' : 'duration';
      this.recordService.group.patchValue({
        inputMode,
      });
    }
  }

  readonly requireFields$ = this.userSettingsQuery.select().pipe(
    map((x) => x.workspace?.settings),
    map((x) => (x ? { name: !!x.requireName, project: !!x.requireProject, task: !!x.requireTask } : null)),
  );
  getErrorObservable(controlName: string) {
    const aliasName =
      {
        name: 'time.name',
      }[controlName] || controlName;
    return useFormErrorObservable(this)(
      controlName,
      () => this.group.controls[controlName],
      {
        required: (error, ctrl) => {
          if (controlName === 'task') return { content: 'errors.record.desc-required', args: {} };
          if (controlName === 'project') return { content: 'errors.record.project-req', args: {} };
          return {
            content: 'errors.required',
            args: { field: aliasName },
          };
        },
        minlength: (error, ctrl) => ({
          content: 'errors.minlength',
          args: { field: aliasName, length: error.requiredLength },
        }),
        minduration: (error) => ({
          content: 'errors.times.atleast_min',
          args: {},
        }),
        same: (error) => ({
          content: 'errors.times.atleast_min',
          args: {},
        }),
        blockEdit: (error, ctrl) => {
          return { content: 'errors.times.blockEdit', args: {} };
        },
      },
      (key) => key,
      {
        initialValidate: true,
      },
    );
  }
  get groupErrors() {
    const { errors } = this.group;
    if (!errors) return null;
    if (errors.range) return 'errors.record.time-invalid-range';
    if (errors.same) return 'errors.times.atleast_min';
    return null;
  }
  ngOnInit(): void {
    this.ref?.updateSize('760px', this.stepperCreate ? '620px' : null);
    this.ref?.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    this.ref?.afterOpened().subscribe(() => {
      this.focusCalendarNow();
    });
    this.stepperCreate?.stepper?.selectionChange
      .pipe(
        filter((x) => x.selectedIndex === 1),
        delay(500),
      )
      .subscribe(() => this.focusCalendarNow());
  }
  openUserPicker() {
    if (!this.workspace$isAdmin.value) return;
    return this.dialog
      .open(UserSinglePickerDialogComponent, {
        data: <UserSinglePickerDialogData>{
          selected: this.group.value?.user,
          filter: ({ selected, ...x }) => {
            const status = ((user) => parseSubscriptionAsStatus(user.workspace, user))(
              this.userSettingsQuery.getValue(),
            );
            return ((x.has_license || status.isTrial) && !x.removed) || selected;
          },
        },
      })
      .afterClosed()
      .subscribe((user) => {
        if (user) {
          this.patchValue({
            user,
          });
          this.group.controls.date.updateValueAndValidity();
        }
      });
  }
  patchValue(data: Partial<typeof this.group.value> = {}, options?: Parameters<typeof this.group.patchValue>[1]) {
    const user = this.userSettingsQuery.getValue();
    if (data.user === null) data.user = user.workspace.users.find((x) => x.id === user.id);
    return this.group.patchValue(data, options);
  }
  onCalChange({ newStart, newEnd }: CalendarEventTimesChangedEvent) {
    const start = formatString(newStart, 'HH:mm'),
      end = formatString(isSameDay(newStart, newEnd) ? newEnd : endOfDay(newStart.getTime()), 'HH:mm');
    this.patchValue({
      start,
      end,
    });
  }
  get hasStepper() {
    return !!this.stepperCreate;
  }
  prevStep() {
    if (!this.hasStepper) return;
    this.stepperCreate.stepIndex.set(0);
  }
  openProjectPicker() {
    if (this.hasStepper) {
      this.prevStep();
      return;
    }
    return this.dialog
      .open(ClientProjectPickerDialogComponent, {
        data: <ClientProjectDialogData>{
          data: {
            selectedProject: this.group.value.project,
            selectedTask: this.group.value.task,
            closeOnRemove: true,
            canToggle: true,
            defaultProject: this.defaultProject,
          },
        },
      })
      .afterClosed()
      .subscribe((payload) => {
        if (!payload || !payload?.length)
          return ((ctrl) => (ctrl.markAsDirty(), ctrl.markAsTouched()))(this.group.controls.project);
        const [x, t]: [Project, Task] = payload || [];
        const newProject = x ?? this.defaultProject;
        this.patchValue({
          project: newProject,
          task: newProject?.useAsDefault ? null : t,
          billable: x.billable ? true : this.group.value.billable,
        });
      });
  }
  openTagsPicker() {
    return this.dialog
      .open(TagPickerDialogComponent, {
        data: <TagDialogData>{
          data: {
            SelectedTags: this.group.value.tags,
            canToggle: true,
            type: TagType.Time,
            allowTypeModify: false,
          },
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((x) => this.patchValue({ tags: x }));
  }
  openTaskPicker() {
    const { project, task } = this.group.value;
    if (!project || project.useAsDefault || project.completed) return;
    return this.dialog
      .open(TaskPickerDialogComponent, {
        data: {
          project: project,
          task: task,
        },
      })
      .afterClosed()
      .subscribe((task: Task & { selected: boolean }) => {
        if (task) this.patchValue({ task: task.selected ? task : null });
        else ((ctrl) => (ctrl.markAsDirty(), ctrl.markAsTouched()))(this.group.controls.project);
      });
  }
  get timeZone() {
    return this.userSettingsQuery.getValue().settings.timeZone;
  }
  get taskDisabled() {
    return !this.group.value.project || this.group.value.project?.useAsDefault || this.group.value.project?.completed;
  }
  allowedDate() {
    const blockTime = this.userSettingsQuery.getValue().workspace.settings.blockTimes;
    const mode: string = blockTime.pastMode;
    const currentuser = this.userSettingsQuery.getValue();
    const isAdmin = !!currentuser.workspace.users.find((x) => x.admin && x.id === currentuser.id);
    let allowedDate;
    if (isAdmin) return null;
    if (currentuser.id != this.group.value.user?.id) return null;
    if (mode === 'none') {
      return allowedDate;
    } else if (mode == 'date') {
      allowedDate = blockTime.pastDate;
    } else {
      const period = blockTime.period;
      const range: string = blockTime.range;
      allowedDate = startOfDay(new Date());
      if (range === 'day') {
        allowedDate.setDate(allowedDate.getDate() - period);
      } else if (range === 'week') {
        allowedDate.setDate(allowedDate.getDate() - period * 7);
      } else if (range === 'month') {
        allowedDate.setMonth(allowedDate.getMonth() - period);
      }
    }
    return allowedDate;
  }

  openCalPicker() {
    return this.dialog
      .open(TimeDatePickerComponent, {
        data: <TimeDatePickerConfig>{
          selectedDate: new Date(this.group.value.date),
          minDate: new Date(this.allowedDate()),
        },
      })
      .afterClosed()
      .pipe(filter((x) => x && isValid(x)))
      .subscribe((x) => {
        this.patchValue({
          date: x,
        });
      });
  }
  async addEntry() {
    if (this.group.invalid) return;
    await nextTickAsync();
    const mode = this.mode,
      inputMode = this.stateVisibleMode ? 'range' : 'duration';
    const val = this.group.getRawValue();
    const baseDate = val.date as Date,
      name = this.group.controls.name.invalid ? this.translate.instant('timer.calendar.newevent') : val.name;
    this.isLoading = true;
    const start: Date = parseFromString(val.start, 'HH:mm', baseDate),
      end: Date = parseFromString(val.end, 'HH:mm', baseDate);

    const nextDuration =
      inputMode === 'duration'
        ? parseDurationSeconds(val.duration)
        : (({ hours, minutes }) => hours * 60 + minutes)(intervalToDuration({ start, end })) * 60;
    const relativeDateStart = startOfDay(start.getTime());
    const userId = this.userSettingsQuery.getValue().id;
    return this.myTimes
      .add({
        name,
        // @ts-ignore
        inputMode,
        ...(mode === 'range_optional'
          ? this.stateVisibleMode
            ? {
                start: start.toISOString(),
                end: end.toISOString(),
                timeDiff: 0,
              }
            : {
                start: relativeDateStart.toISOString(),
                end: relativeDateStart.toISOString(),
                timeDiff: nextDuration,
              }
          : mode === 'duration'
            ? {
                timeDiff: nextDuration,
                start: relativeDateStart.toISOString(),
                end: relativeDateStart.toISOString(),
              }
            : { start: start.toISOString(), end: end.toISOString() }),
        billable: !!val.billable,
        project: { id: val.project.id },
        // @ts-ignore
        task: val.task,
        tags: val.tags || [],
        outlookCalenderReference: this.data.outlookRefId || undefined,
        recordType: 'manual',
        ...(val.user ? { user: { id: val.user.id, name: val.user.name } } : {}),
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (x) => {
          this.myTimesQuery.__store__.remove(x.filter((y) => y && y.user?.id !== userId).map((y) => y.id));
          this.ref.close(x);
        },
        error: (err) => {
          this.recordService.handleError(err);
        },
      });
  }
  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }

  @ViewChild('timeContextMenuTrigger', { static: true })
  timeContextMenu: MatMenuTrigger;

  timeContextMenuPosition = { x: '0px', y: '0px' };

  onTimeContextMenuTrigger(event: MouseEvent, item: { time: Time; prop: string }) {
    event.stopPropagation(), event.preventDefault();
    this.timeContextMenuPosition.x = event.clientX + 'px';
    this.timeContextMenuPosition.y = event.clientY + 'px';
    this.timeContextMenu.menuData = { $implicit: item };
    this.timeContextMenu.menu.focusFirstItem('mouse');
    this.timeContextMenu.openMenu();
  }
  setToNow(prop: 'start' | 'end') {
    return this.group.controls[prop].patchValue(flow((date) => formatString(date, 'HH:mm'))(new Date()));
  }
  setMinutesDiff(minutes: number, prop: 'start' | 'end') {
    switch (prop) {
      case 'start':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), subMinutes(minutes), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm'),
          )(this.group.value.end as string),
        );
      case 'end':
        return this.group.controls[prop].patchValue(
          flow(
            parsefp(new Date(), 'HH:mm'),
            (date) => addMinutes(date, minutes),
            clampDay(new Date()),
            (date) => formatString(date, 'HH:mm'),
          )(this.group.value.start as string),
        );
    }
  }
  setHourDiff(hours: number, prop: 'start' | 'end') {
    switch (prop) {
      case 'start':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), subHours(hours), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm'),
          )(this.group.value.end as string),
        );
      case 'end':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), addHours(hours), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm'),
          )(this.group.value.start as string),
        );
    }
  }
  setWorkDay() {
    return this.patchValue({
      start: '09:00',
      end: '17:00',
    });
  }
  ngAfterContentInit() {
    setTimeout(() => {
      this.focusCalendarNow();

      this.group.updateValueAndValidity();
    }, 50);
  }
}
export interface TimeTrackCreateData {
  timeDiff?: number;
  title?: string;
  start?: Date;
  end?: Date;
  date?: Date;
  project?: Project;
  task?: Task;
  billable?: boolean;
  lang?: { submit?: string };
  outlookRefId?: string;
  user?: CosmosEntity;
  tags: Tag[];
}
