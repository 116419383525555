import { FocusMonitor } from '@angular/cdk/a11y';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControlStatus, UntypedFormGroup } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { translateArgs } from '@app/_helpers/translate-mutator';
import {
  coerceTimeDuration,
  createRxValue,
  distinctUntilBy,
  distinctUntilChangedJson,
  fromRxValue,
} from '@app/_helpers/utils';
import { FadeInOut, SlideUpDown, SlideUpDownSync } from '@app/animations/fade';
import { AppService } from '@app/app.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { differenceInSeconds, endOfDay, isAfter, isToday, isValid, parse as parseFromString } from 'date-fns/esm';
import { format, set, startOfDay } from 'date-fns/esm/fp';
import produce from 'immer';
import { flow } from 'lodash';
import { BehaviorSubject, Subject, combineLatest, of, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { firstBy } from 'thenby';
import {
  ApplicationSettingsQuery,
  ClientsQuery,
  FeedEntry,
  FeedQuery,
  Logger,
  MyTimesQuery,
  MyTimesService,
  Project,
  ProjectsQuery,
  ProjectsService,
  SearchService,
  Tag,
  TagType,
  Task,
  Time,
  UserService,
  UserSettings,
  UserSettingsQuery,
} from 'timeghost-api';

import startWithDefault from '@app/_operators/startWithDefault';
import { parseDuration } from '@app/components/duration-input-control/duration-input-utils';
import {
  ClientProjectPickerStepperData,
  ClientProjectPickerStepperDialogComponent,
} from '../dialogs/client-project-picker-stepper-dialog/client-project-picker-stepper-dialog.component';
import { TagDialogData, TagPickerDialogComponent } from '../dialogs/tag-picker-dialog/tag-picker-dialog.component';
import { RecordToolbarService } from './record-toolbar.service';

const log = new Logger('RecordToolbar');

export enum SelectMode {
  Timer,
  Manual,
}
export interface TempSaveRecord {
  start: string;
  taskName: string;
  billing: boolean;
  project: Project;
  tags: Tag[];
}
@UntilDestroy()
@Component({
  selector: 'app-record-toolbar',
  templateUrl: './record-toolbar.component.html',
  styleUrls: ['./record-toolbar.component.scss'],
  animations: [FadeInOut, SlideUpDownSync, SlideUpDown],
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: 'record-toolbar-host',
  },
  // encapsulation: ViewEncapsulation.ShadowDom
})
export class RecordToolbarComponent implements OnInit, AfterViewInit {
  @Input()
  set isLoading(val: boolean) {
    this.recordService.isLoading = val;
    this.cdr.markForCheck();
  }
  get isLoading() {
    return this.recordService.isLoading;
  }
  isLoading$ = this.recordService.isLoading$;
  get manualStartDate(): Date {
    return this.group.value.time.recordStart;
  }
  set manualStartDate(val: Date) {
    this.group.patchValue({
      time: {
        recordStart: val,
      },
    });
  }

  updateStartDate(dt: Date) {
    if (!dt) return;
    this.manualStartDate = new Date(dt.getTime());
  }

  get group() {
    return this.recordService.group;
  }
  set group(g: UntypedFormGroup) {
    this.recordService.group = g;
  }
  readonly manualStartDateNative$ = this.group.valueChanges.pipe(
    map((x) => (x.time?.recordStart && isValid(x.time.recordStart) ? x.time.recordStart : null)),
  );
  readonly group$disabled = combineLatest([
    this.userSettingsQuery.select(),
    this.group.valueChanges,
    this.group.statusChanges,
  ]).pipe(
    map(([user, value]) => {
      if (this.group.disabled || this.group.invalid) return true;
      return false;
    }),
  );

  value$ = this.recordService.group.valueChanges.pipe(distinctUntilChanged(), debounceTimeAfterFirst(100));
  value$project = fromRxValue(this.value$.pipe(map((x) => x?.project)), this.recordService.group.value.project);
  value$task = this.value$.pipe(map((x) => x.task));
  value$start = this.value$.pipe(
    map((x) => (x.time?.start ? parseFromString(x.time.start as string, 'HH:mm', new Date()) : null)),
    map((x) => (isValid(x) ? x : null)),
  );
  value$end = this.value$.pipe(
    map((x) => (x.time?.end ? parseFromString(x.time.end as string, 'HH:mm', new Date()) : null)),
    map((x) => (isValid(x) ? x : null)),
  );
  value$tags = this.value$.pipe(map((x) => x.tags?.length > 0));
  value$billable = this.value$.pipe(map((x) => !!x.billable));
  modes: typeof SelectMode = SelectMode;
  @ViewChild('descriptionList', { static: true })
  taskSearch: MatAutocomplete;

  @Output()
  get isPlayingChange() {
    return this.recordService.isPlayingChange;
  }
  @Input()
  get isPlaying() {
    return this.recordService.isPlaying;
  }
  set isPlaying(val: boolean) {
    this.recordService.isPlaying = val;
    this.cdr.markForCheck();
  }
  @Output()
  get SelectedModeChange() {
    return this.recordService.selectedRecordMode$;
  }
  @Input()
  get SelectedRecordMode() {
    return this.recordService.selectedRecordMode;
  }
  set SelectedRecordMode(val: SelectMode) {
    this.recordService.selectedRecordMode = val;
    this.cdr.markForCheck();
  }

  private _taskSearchLoading = new BehaviorSubject<boolean>(false);
  readonly taskSearchLoading$ = this._taskSearchLoading.asObservable().pipe(distinctUntilChanged());
  get taskSearchLoading() {
    return this._taskSearchLoading.getValue();
  }
  set taskSearchLoading(val: boolean) {
    this._taskSearchLoading.next(val);
  }
  readonly showMoreTasks = createRxValue(false);
  readonly showMoreFeed = createRxValue(false);
  readonly entries$latestTimes = combineLatest([
    this.myTimesQuery.selectAll(),
    this.feedQuery.selectAll().pipe(startWith(this.feedQuery.getAll())),
    this.showMoreTasks.asObservable(),
    this.showMoreFeed.asObservable(),
    this.userSettingsQuery.select(),
  ]).pipe(
    map(([times, feed, _showMoreTasks, _showMoreFeed]) => {
      times = times
        .filter((x) => x.start && x.end && !x.project?.completed && (x.project?.useAsDefault ? !!x.task : true))
        ?.uniqBy((x) => `${x.task?.id}${x.project?.id}`)
        .filter((x) => x && !x.project?.completed);
      feed = feed.filter((x) => x.start && x.end && !x.booked);
      const showMoreTasks = times?.length > 8 ? _showMoreTasks : undefined,
        showMoreFeed = feed?.length > 8 ? _showMoreFeed : undefined;
      if (!showMoreTasks) times = times.slice(0, 8);
      if (!showMoreFeed) feed = feed.slice(0, 8);
      return <[Time[], FeedEntry[], boolean, boolean]>[times, feed, showMoreTasks, showMoreFeed];
    }),
    map(([times, feed, showMoreTasks, showMoreFeed]) => {
      const entries: { name: string; type: string; id: string; date: Date; [key: string]: any }[] = [
        ...(times
          ?.map((x) => ({ name: x.name, type: 'time', id: x.id, data: x, date: new Date(x.start) }))
          .uniqBy((x) => x.id) || []),
        ...(feed
          ?.map((x) => ({ name: x.name, type: 'feed', id: x.id, data: x, date: new Date(x.start) }))
          .filter((x) => isToday(x.date))
          .uniqBy((x) => x.id) || []),
      ];
      if (entries.length === 0) return null;
      return entries.sort(firstBy((x) => x.date, 'desc')).reduce(
        (l, r) => {
          if (r.type === 'feed') l.feed.push(r);
          else if (r.type === 'time') l.times.push(r);
          l.length = l.feed.length + l.times.length;
          return l;
        },
        { feed: <typeof entries>[], times: <typeof entries>[], length: 0, showMoreTasks, showMoreFeed },
      );
    }),
  );

  constructor(
    private myTimesService: MyTimesService,
    private appService: AppService,
    private userService: UserService,
    private translateService: TranslateService,
    private searchService: SearchService,
    private recordService: RecordToolbarService,
    private projectsQuery: ProjectsQuery,
    private projectsService: ProjectsService,
    private clientsQuery: ClientsQuery,
    private userSettingsQuery: UserSettingsQuery,
    private myTimesQuery: MyTimesQuery,
    private feedQuery: FeedQuery,
    private fm: FocusMonitor,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private appSettings: ApplicationSettingsQuery,
  ) {
    const userSettings = this.userSettingsQuery.getValue();
    this.SelectedRecordMode =
      userSettings.settings.captureManualMode !== undefined
        ? userSettings.settings.captureManualMode
          ? SelectMode.Manual
          : SelectMode.Timer
        : SelectMode.Timer;
  }
  get displayName() {
    return this.userSettingsQuery.getValue()?.officeProfile?.displayName || '-';
  }
  errorChange = new Subject<void>();
  initialFocus = new Subject<void>();
  settings$ = this.userSettingsQuery.select((x) => x.workspace.settings).pipe(distinctUntilChanged());
  settings$allowFutureTimeTracking = this.settings$.pipe(
    map((x) => (x.allowFutureTimeTracking ? null : endOfDay(new Date()))),
  );
  get runningTime() {
    return this.recordService.runningTime;
  }
  readonly errors$ = combineLatest([
    this.recordService.selectedRecordMode$.pipe(startWith(this.SelectedRecordMode), distinctUntilChanged()),
    this.runningTime.asObservable(),
    this.group.statusChanges.pipe(startWith(this.group.status)),
    this.group.valueChanges.pipe(startWith(this.group.value)),
    this.errorChange,
    this.initialFocus,
  ]).pipe(
    debounceTimeAfterFirst(100),
    map(
      ([mode, running, state, value, ...args]: [
        SelectMode,
        Time,
        FormControlStatus,
        RecordToolbarService['group']['value'],
        ...any[],
      ]) => {
        const nameErrors = this.group.controls?.name?.errors;
        const nameMinLength = nameErrors?.minlength;
        if (nameMinLength)
          return {
            translate: 'errors.minlength',
            args: { field: 'RECORD_TOOLBAR_DESCRIPTION', length: nameMinLength.requiredLength },
          };
        if (nameErrors?.required)
          return {
            translate: 'errors.required',
            args: { field: 'RECORD_TOOLBAR_DESCRIPTION' },
          };
        if (!value.project) return 'errors.record.project-req';
        if (mode === SelectMode.Manual && this.stateVisibleMode) {
          let _start = parseFromString(value.time.start, 'HH:mm', new Date()),
            _end = parseFromString(value.time.end, 'HH:mm', new Date());
          if (!isValid(_start) || !isValid(_end)) return 'errors.record.time-invalid';
          const diffSec = differenceInSeconds(_end, _start);
          if (diffSec <= 0) return 'errors.times.too_short';
          if (diffSec < 30) return 'errors.record.time-invalid-range';
        } else if (mode === SelectMode.Manual && !this.stateVisibleMode) {
          const duration = coerceTimeDuration(value.time.duration);
          const start = startOfDay(duration.getTime());
          const diffSec = differenceInSeconds(duration, start);
          if (diffSec <= 0) return 'errors.times.too_short';
          if (diffSec < 30) return 'errors.record.time-invalid-range';
        }
        const user = this.userSettingsQuery.getValue();
        if (
          user.workspace.settings?.requireProject &&
          (mode === SelectMode.Timer
            ? running
              ? !value.project || (this.projectsQuery.getEntity(value.project.id) ?? value.project)?.useAsDefault
              : false
            : value.project
              ? (this.projectsQuery.getEntity(value.project.id) ?? value.project)?.useAsDefault
              : true)
        ) {
          return 'errors.record.project-req';
        }
        if (
          user.workspace.settings?.requireTask &&
          (mode === SelectMode.Timer ? (running ? !value.task : false) : !value.task)
        ) {
          return { translate: 'errors.record.desc-required', args: { field: 'Task' } };
        }
        return null;
      },
    ),
    map((x: string | { args: Object }) => {
      if (!!x && typeof x === 'object' && x?.args && Object.keys(x.args).length > 0)
        x.args = translateArgs(x.args, (v, args) => this.translateService.instant(v, args)) as typeof x.args;
      return x;
    }),
    tap(() => this.cdr.markForCheck()),
  );
  readonly errors$timer = combineLatest([
    this.runningTime.asObservable().pipe(startWithDefault()),
    this.errors$.pipe(startWithDefault()),
    this.SelectedModeChange,
    timer(0, 1000).pipe(startWithDefault(), untilDestroyed(this)),
  ]).pipe(
    map(([time, error, mode]) => {
      if (!time) return error;
      if (time?.start && mode === SelectMode.Timer) {
        const now = new Date();
        const _start = new Date(time.start);
        const diffSec = differenceInSeconds(now, _start);
        if (diffSec <= 0) return 'errors.times.too_short';
        if (diffSec < 60) return 'errors.times.atleast_min';
      }
      return error;
    }),
    map((x: string | { args: Object }) => {
      if (!!x && typeof x === 'object' && x?.args && Object.keys(x.args).length > 0)
        x.args = translateArgs(x.args, (v, args) => this.translateService.instant(v, args)) as typeof x.args;
      return x;
    }),
  );
  clearTaskName() {
    this.group.patchValue({
      name: '',
    });
  }
  trackId(index: number, entry: { id: string }) {
    return entry.id;
  }
  markInitial(enabled?: boolean) {
    if (enabled === true) this.initialEnabled = enabled;
    this.initialFocus.next();
    this.errorChange.next();
  }
  focusToolbar() {
    return document.getElementById('record_toolbar_wrapper')?.focus();
  }
  async startAutomodeData() {
    const value = this.group.value;
    const projectId = value.project?.id;
    const [time] = await this.myTimesService
      .add({
        project: projectId ? { id: projectId } : null,
        // @ts-ignore
        task: value.task,
        name: value.name,
        start: new Date(),
        billable: value.billable,
        tags: value.tags || [],
        end: null,
      })
      .toPromise()
      .catch((err) => {
        this.recordService.handleError(err);
        return [null];
      });
    if (!time) return;
    this.group.patchValue(
      {
        name: time.name,
        billable: !!time?.billable,
        tags: time.tags || [],
        time: {
          start: format('HH:mm')(new Date(time.start)),
          end: format('HH:mm')(new Date(time.start)),
        },
      },
      { emitEvent: false },
    );
    this.manualStartDate = new Date(time.start);
    this.isPlaying = true;
    this.SelectedRecordMode = SelectMode.Timer;
    this.cdr.markForCheck();
    return time;
  }
  async stopAutomodeData() {
    const time = this.myTimesQuery.getAll({ filterBy: (x) => x.end === null })[0];
    if (!time) return null;
    this.isPlaying = false;
    const value = this.group.value;
    const newTime = produce(time, (draft) => {
      draft.name = value.name;
      draft.tags = value.tags || [];
      draft.billable = !!value.billable;
      draft.project = value.project || this.defaultProject;
      if (new Date() > endOfDay(new Date(draft.start))) {
        draft.end = endOfDay(new Date(draft.start)).toISOString();
      } else {
        draft.end = new Date().toISOString();
      }
    });
    const resolvedTime = await this.myTimesService.update(newTime).toPromise();
    this.cdr.markForCheck();
    return newTime;
  }
  initialEnabled = false;
  hasError = createRxValue(false);
  runningTime$counter = this.runningTime.asObservable().pipe(
    map((time) => {
      return time?.start && !time.end && new Date(time.start);
    }),
  );
  private async play(playState: boolean) {
    this.isLoading = true;
    log.debug('playstate', playState);

    if (playState) {
      return this.startAutomodeData()
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    } else {
      this.recordService
        .stopRecord({ showUpdate: false, checkServiceControls: true })
        .then(() => {
          this.isLoading = false;
          this.recordService.resetAll();
        })
        .catch(() => {
          this.isLoading = false;
          this.recordService.resetDate();
        });
    }
  }
  readonly projectTooltipData$ = this.group.valueChanges.pipe(
    map((val) => {
      return {
        project: val.project,
        task: val.task,
        tags: val.project?.tags,
      };
    }),
  );
  now() {
    return new Date();
  }
  private get defaultProject() {
    return this.projectsQuery.getAll({
      filterBy: (x) => x.useAsDefault === true,
      limitTo: 1,
    })[0];
  }
  focusAutocomplete() {
    // if (this.taskSearch.panel.nativeElement) {
    //   const el = (this.taskSearch.panel.nativeElement as HTMLElement)?.querySelector('.mat-list') as HTMLElement;
    //   if (el) el.focus();
    // }
  }
  ngOnInit() {
    this.manualStartDate = new Date();
    this.recordService.onClear.pipe(untilDestroyed(this)).subscribe(() => this.clearData());
    this.SelectedModeChange.pipe(
      distinctUntilChanged(),
      debounceTime(2000),
      untilDestroyed(this),
      switchMap((x) => {
        const { captureManualMode } = this.userSettingsQuery.getValue().settings;
        if (x === SelectMode.Timer && !!captureManualMode) {
          return this.userService.changeSettings({ captureManualMode: false });
        }
        if (x === SelectMode.Manual && !captureManualMode) {
          return this.userService.changeSettings({ captureManualMode: true });
        }
        return of(null);
      }),
    ).subscribe();
    this.userSettingsQuery
      .select()
      .pipe(distinctUntilChanged((l, r) => l?.workspace?.id === r?.workspace?.id))
      .subscribe(() => {
        this.group.updateValueAndValidity(), this.onHostFocus(), this.errorChange.next();
      });

    if (!this.group.value.project && this.defaultProject) {
      this.group.patchValue({
        project: this.defaultProject,
        ref: null,
      });
    }
    const value = this.group.value;
    if (value.time?.start === '00:00' && value.time?.end === '00:00' && this.mode !== 'duration') {
      this.recordService.reset(); // reset time
    }
  }
  async ngAfterViewInit() {
    const userSettings = this.userSettingsQuery.getValue();
    this.fm
      .monitor(this.el.nativeElement, true)
      .pipe(take(1))
      .subscribe(() => this.onHostFocus());
    if (userSettings.settings.captureManualMode) {
      this.changeMode(SelectMode.Manual);
    }
    this.errors$timer.pipe(untilDestroyed(this)).subscribe((x) => {
      this.hasError.value = x !== null;
    });
    this.recordService.events$.pipe(filter((x) => x === 'COPY_ENTRY')).subscribe(() => {
      this.onHostFocus();
    });
    this.runningTime
      .asObservable()
      .pipe(distinctUntilBy((x: any) => x?.id))
      .subscribe((x) => {
        this.isPlaying = !!x;
        if (x) {
          const project = this.projectsQuery.getEntity(x.project.id);
          this.group.patchValue({
            billable: !!x.billable,
            tags: x.tags || [],
            project: project || this.group.value.project,
            task: x.task ?? this.group.value.task,
            name: x.name,
          });
        }
        this.cdr.markForCheck();
      });
    this.group.updateValueAndValidity();

    if (this.defaultProject && this.group.valid) {
      this.markInitial(true);
    } else this.errorChange.next();
    this.recordService.initialize();
    this.cdr.markForCheck();
  }
  isDefaultProject(id: string) {
    return this.projectsQuery.getCount((x) => x.id === id && x.useAsDefault) > 0;
  }
  isDefaultClient(id: string) {
    return this.clientsQuery.getCount((x) => x.id === id && x.useAsDefault) > 0;
  }
  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }
  openClientProjectPickerDialog(ev: Event) {
    this.dialog
      .open(ClientProjectPickerStepperDialogComponent, {
        data: <ClientProjectPickerStepperData>{
          skipProjectStepper: false,
          project: this.group.value.project,
          task: this.group.value.task,
          // selectedProject: this.group.value.project,
          // selectedTask: this.group.value.task,
          // canToggle: true,
          // closeOnRemove: true,
          // defaultProject: this.defaultProject,
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(([x, t]: [Project, Task]) => {
        const newProject = x ?? this.defaultProject;
        this.group.patchValue({
          project: newProject,
          task: newProject?.useAsDefault ? null : (t ?? null),
          billable: x.billable ? true : this.group.value.billable,
        });
        this.cdr.markForCheck();
      });
  }
  get timeZone() {
    return this.userSettingsQuery.getValue().settings.timeZone;
  }
  get mode() {
    return this.userSettingsQuery.getValue()?.workspace.settings?.timesMode ?? 'range';
  }
  openTagPickerDialog(ev: Event) {
    this.dialog
      .open(TagPickerDialogComponent, {
        data: <TagDialogData>{
          data: {
            SelectedTags: this.selectedTags || [],
            canToggle: true,
            type: TagType.Time,
            allowTypeModify: false,
          },
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(
        (x) => (
          this.group.patchValue({
            tags: x || [],
          }),
          this.cdr.markForCheck()
        ),
      );
  }
  clearData() {
    this.recordService.resetAll();
  }
  saveManualData() {
    if (this.group.invalid) return;
    const value = this.recordService.group.value;
    const _tgStart = parseFromString(value.time.start, 'HH:mm', new Date());
    const _tgEnd = parseFromString(value.time.end, 'HH:mm', new Date());
    const duration = (([dhours, dminutes]) => ~~dhours * 60 + ~~dminutes)(parseDuration(value.time.duration)) * 60;
    let start = flow(
        startOfDay,
        set({ hours: _tgStart.getHours(), minutes: _tgStart.getMinutes(), milliseconds: 0 }),
      )(this.manualStartDate || new Date()),
      end = flow(
        startOfDay,
        set({ hours: _tgEnd.getHours(), minutes: _tgEnd.getMinutes(), milliseconds: 0 }),
      )(this.manualStartDate || new Date());
    if (isAfter(start, end)) return;
    if (!this.VerifyBlockTimePast(this.userSettingsQuery.getValue(), start)) {
      return;
    }
    this.saveRecord(start, end, duration, value.project);
  }
  get stateVisibleMode() {
    return this.group.value.inputMode === 'range';
  }
  set stateVisibleMode(v: boolean) {
    if (this.userSettingsQuery.getValue().workspace?.settings?.timesMode === 'range_optional') {
      const inputMode = v ? 'range' : 'duration';
      this.group.patchValue({
        inputMode,
      });
    }
  }
  async saveRecord(startTime: Date, endTime: Date, duration: number, project: Project) {
    const startedAt: Date = startTime,
      endedAt: Date = endTime;
    if (isAfter(startedAt, endedAt)) return;
    const value = this.recordService.group.value;
    await this.recordService.save({
      timeDiff: duration,
      name: value.name,
      task: value.task,
      start:
        startedAt.getDate() !== startTime.getDate()
          ? new Date(startedAt.setDate(startTime.getDate())).toDateString()
          : startedAt.toISOString(),
      end:
        endedAt.getDate() !== endTime.getDate()
          ? new Date(endedAt.setDate(endTime.getDate())).toISOString()
          : endedAt.toISOString(),
      inputMode: this.stateVisibleMode ? 'range' : 'duration',
      billable: !!value.billable,
      project,
      tags: [...(value.tags || [])],
      outlookCalenderReference: value.ref,
    });
    this.recordService.hasPendingInput = false;
  }
  get maxEndTime() {
    return new Date();
  }
  get selectedTags() {
    return this.recordService.group.value.tags;
  }
  autoDisplayWith(data: any): string | undefined {
    return data || '';
  }
  removeProject() {
    this.recordService.resetProject(true);
    this.cdr.markForCheck();
  }
  changeProject(project: Project, name?: string) {
    if (!project) project = this.defaultProject;
    const task = this.group.value.task;
    this.group.patchValue({
      name,
      project: project,
      billable: !!project?.billable,
      task: task?.project?.id !== project.id ? null : task,
    });
    this.cdr.markForCheck();
  }
  taskSelected(ev: Event, data: any) {
    ev.stopPropagation();
    ev.preventDefault();
    const { task, client, id: outlookCalenderReference } = data;
    const project = {
      id: data.project.id,
      name: data.project.name,
      billable: !!data.project?.billable,
      client,
    };
    this.group.patchValue({
      name: '',
      task: task,
      billable: !!(project.billable ?? this.projectsQuery.getEntity(project.id)?.billable),
      project,
      ref: outlookCalenderReference,
    });
  }
  timeSelected(time: Time) {
    return this.recordService.copyRecording(
      {
        ...time,
        name: this.recordService.group.value.name || '',
        billable: (time.project as any)?.billable ?? !!this.projectsQuery.getEntity(time.project?.id)?.billable,
      },
      { recordMode: this.recordService.selectedRecordMode },
    );
  }
  feedSelected(feed: FeedEntry) {
    this.recordService.group.patchValue(
      ((data) => {
        data = {
          ...data,
          time: {
            start: flow((x) => new Date(x), format('HH:mm'))(feed.start),
            end: flow((x) => new Date(x), format('HH:mm'))(feed.end),
          },
        };
        if (data.project?.billable) data.billable = true;
        return data;
      })({
        name: feed.name,
        ref: feed.id,
      } as any),
    );
  }

  entries$suggestions = combineLatest([this.group.valueChanges, this.userSettingsQuery.select((x) => x.workspace?.id)])
    .pipe(startWith([this.group.value, this.userSettingsQuery.getValue()?.workspace?.id]))
    .pipe(map(([x, wsId]) => ({ name: x.name, project: x.project, task: x.task, workspaceId: wsId })))
    .pipe(
      distinctUntilChangedJson(),
      debounceTimeAfterFirst(1000),
      switchMap((selected) => {
        if (!selected.name) return Promise.resolve([]);
        return this.projectsService
          .findByTaskName(selected.name)
          .then((entries) => entries.uniqBy(({ project, task }) => `${project?.id}${task?.id}`))
          .then((entries) =>
            !selected.project?.id || selected.project?.useAsDefault
              ? entries
              : entries.filter((x) => x.project?.id === selected.project),
          )
          .then((entries) => {
            log.debug('suggestions', entries);
            return entries
              .filter((x) => {
                if (!x) return x;
                const projectType = this.projectsQuery.getEntity(x.project.id)?.projectType;
                if (!projectType || projectType === 'project') return true;
                return false;
              })
              .map(({ project, task }) => {
                return {
                  ...project,
                  useAsDefault:
                    (project as Project).useAsDefault ?? this.projectsQuery.getEntity(project.id)?.useAsDefault,
                  task,
                  selected: selected && task && selected.project?.id === project.id && selected.task?.id === task.id,
                };
              });
          })
          .then((entries) => entries.filter((x) => !x.useAsDefault));
      }),
    );
  submitSuggestion({ project, task }: { project: Project; task: Task }) {
    this.group.patchValue({
      project,
      task,
      billable: !!(project.billable ?? this.projectsQuery.getEntity(project.id)?.billable),
    });
  }

  getProjectColor(id: string) {
    return this.projectsQuery.getEntity(id)?.color;
  }
  get HFormat() {
    return this.appService.timeFormat;
  }
  togglePlaying() {
    this.play(!this.runningTime.value);
  }
  togglePayment() {
    this.group.patchValue({
      billable: !this.group.value.billable,
    });
    this.cdr.markForCheck();
  }
  get currentLang() {
    return this.translateService.currentLang;
  }
  changeMode(mode: SelectMode) {
    this.SelectedRecordMode = mode;
    this.group.updateValueAndValidity();
  }
  @HostListener('focus')
  onHostFocus() {
    this.initialEnabled = true;
    this.initialFocus.next();
  }

  copyContents(content: string) {
    this.clipboard.copy(content);
    this.cdr.markForCheck();
  }
  VerifyBlockTimePast(currentuser: UserSettings, date: Date) {
    const blockTimes = currentuser.workspace.settings.blockTimes;
    const mode: string = blockTimes.pastMode;
    const isAdmin = !!currentuser.workspace.users.find((x) => x.admin && x.id === currentuser.id);
    let allowedDate;
    if (mode === 'none') {
      return true;
    } else if (mode == 'date') {
      allowedDate = new Date(blockTimes.pastDate);
    } else {
      const period = blockTimes.period;
      const range: string = blockTimes.range;
      allowedDate = startOfDay(new Date());
      if (range === 'day') {
        allowedDate.setDate(allowedDate.getDate() - period);
      } else if (range === 'week') {
        allowedDate.setDate(allowedDate.getDate() - period * 7);
      } else if (range === 'month') {
        allowedDate.setMonth(allowedDate.getMonth() - period);
      }
    }
    if (isAdmin) {
      return true;
    }
    if (date >= allowedDate) {
      return true;
    } else {
      this.appService.notifier.show({
        message: this.translateService.instant('errors.entry.blockCreate'),
        type: 'error',
      });
      return false;
    }
  }
}
