import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { endOfDay } from 'date-fns/esm';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UserSettingsQuery } from 'timeghost-api';
import { TimeDatePickerConfig } from './time-date-picker-config';

@Component({
  selector: 'app-time-date-picker',
  templateUrl: './time-date-picker.component.html',
  styleUrls: ['./time-date-picker.component.scss'],
})
export class TimeDatePickerComponent implements OnInit, AfterViewInit {
  @ViewChild('calview', { static: true })
  calendar: MatCalendar<Date>;
  private _selectedDate = new BehaviorSubject<Date>(null);
  readonly selectedDate$ = this._selectedDate.asObservable().pipe(distinctUntilChanged());
  get selectedDate() {
    return this._selectedDate.getValue();
  }
  set selectedDate(val: Date) {
    this._selectedDate.next(val);
  }
  private _minDate = new BehaviorSubject<Date>(null);
  readonly minDate$ = this._minDate.asObservable().pipe(distinctUntilChanged());
  get minDate() {
    return this._minDate.getValue();
  }
  set minDate(val: Date) {
    this._minDate.next(val);
  }
  readonly settings$allowFutureTimeTracking = this.userSettingsQuery
    .select((x) => x.workspace.settings)
    .pipe(map((x) => (x.allowFutureTimeTracking ? null : endOfDay(new Date()))));

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: TimeDatePickerConfig,
    private userSettingsQuery: UserSettingsQuery,
    private ref: MatDialogRef<TimeDatePickerComponent>,
  ) {}

  ngOnInit(): void {
    if (this.data.selectedDate) this.selectedDate = this.data.selectedDate;
    if (this.data.minDate) this.minDate = this.data.minDate;

    this.ref.addPanelClass('mat-dialog-nopad'), this.ref.updateSize('320px');
  }
  ngAfterViewInit() {
    if (!this.data.selectedDate) {
      this.calendar.monthView.activeDate = new Date();
    }
  }
  close(val: Date) {
    return this.ref.close(val);
  }
}
